import React from 'react';
import { Typography, Descriptions, Card, Tag, Empty, Grid, Tabs, List } from 'antd';
import { format } from 'date-fns';
import { ActivityInfoRole, ActivityInfoRoleParse } from '@utils/constans';
import { Collapse } from 'antd';
import PropTypes from 'prop-types';
import { OrderStatus, OrderStatusName } from '../../utils/constans';

const { Title } = Typography;
const { useBreakpoint } = Grid;

function OrderItemDetail({ orderItemData, contacts = [] }) {
    const screens = useBreakpoint();

    if (!orderItemData || Object.keys(orderItemData).length === 0) {
        return <Empty description="No order item data available" />;
    }

    const formatDate = (dateString) => {
        return dateString ? format(new Date(dateString), 'PPP') : 'N/A';
    };

    const renderInCard = (title, content) => (
        <Card title={title} style={{ marginBottom: 20 }}>
            {content}
        </Card>
    );

    const orderInfo = (
        <Descriptions bordered>
            <Descriptions.Item label="Order Ref">{orderItemData.ref || 'N/A'}</Descriptions.Item>
            <Descriptions.Item label="Created At">{formatDate(orderItemData.createdAt)}</Descriptions.Item>
            <Descriptions.Item label="Updated At">{formatDate(orderItemData.updatedAt)}</Descriptions.Item>
            <Descriptions.Item label="Status">
                {/* 供应商状态 */}
                <Tag color={orderItemData.status === OrderStatus.Cancelled ? 'red' : 'green'}>
                    {OrderStatusName(orderItemData.status)}
                </Tag>
            </Descriptions.Item>
            <Descriptions.Item label="Confirmed">
                <Tag color={orderItemData.confirmed ? 'green' : 'orange'}>{orderItemData.confirmed ? 'Yes' : 'No'}</Tag>
            </Descriptions.Item>
        </Descriptions>
    );

    const activityInfo = () => {
        if (orderItemData.activity && Object.keys(orderItemData.activity).length > 0) {
            return (
                <>
                    <Descriptions bordered>
                        <Descriptions.Item label="Name">{orderItemData.activity.name || 'N/A'}</Descriptions.Item>
                        <Descriptions.Item label="English Name">
                            {orderItemData.activity.nameEN || 'N/A'}
                        </Descriptions.Item>
                        <Descriptions.Item label="Tour Code">
                            {orderItemData.activity.tourCode || 'N/A'}
                        </Descriptions.Item>
                    </Descriptions>
                    {information(orderItemData.activity.information)}

                    {orderItemData.activity.content ? (
                        <Collapse
                            bordered={false}
                            style={{ marginTop: 20 }}
                            items={[
                                {
                                    key: '1',
                                    label: 'Activity Description',
                                    children: (
                                        <div dangerouslySetInnerHTML={{ __html: orderItemData.activity.content }} />
                                    ),
                                },
                            ]}
                        />
                    ) : (
                        <Empty description="No description available" />
                    )}
                </>
            );
        } else {
            return <Empty description="No activity information available" />;
        }
    };

    const renderQuantityInfo = () => {
        const { effectPricing, bookingInfo } = orderItemData;
        if (effectPricing.bundlePriced) {
            return (
                <Descriptions.Item label="Quantity" span={2}>
                    {bookingInfo.bundleQty ?? 'N/A'}
                </Descriptions.Item>
            );
        } else {
            return (
                <>
                    <Descriptions.Item label="Adult Qty">{bookingInfo.adult}</Descriptions.Item>
                    <Descriptions.Item label="Child Qty">{bookingInfo.child}</Descriptions.Item>
                    {effectPricing.hasFamilyPlan && bookingInfo.family > 0 && (
                        <Descriptions.Item label="Family Qty" span={3}>
                            {bookingInfo.family}
                        </Descriptions.Item>
                    )}
                </>
            );
        }
    };

    const bookingInfo = () => {
        if (orderItemData.bookingInfo && Object.keys(orderItemData.bookingInfo).length > 0) {
            return (
                <>
                    <Descriptions bordered column={3}>
                        <Descriptions.Item label="Date">{orderItemData.bookingInfo.date || 'N/A'}</Descriptions.Item>
                        {renderQuantityInfo()}
                        {orderItemData.bookingInfo.upgraded && orderItemData.bookingInfo.variant && (
                            <>
                                <Descriptions.Item label="Upgraded Variant" span={3}>
                                    {`${orderItemData.bookingInfo.variant.nameEN || 'N/A'} (${
                                        orderItemData.bookingInfo.variant.nameZH || 'N/A'
                                    })`}
                                </Descriptions.Item>
                                <Descriptions.Item label="Variant Description" span={3}>
                                    {orderItemData.bookingInfo.variant.description || 'N/A'}
                                </Descriptions.Item>
                            </>
                        )}
                        {orderItemData.bookingInfo.segmented && orderItemData.bookingInfo.segment && (
                            <Descriptions.Item label="Segment" span={3}>
                                {orderItemData.bookingInfo.segment.value || 'N/A'}
                            </Descriptions.Item>
                        )}
                        {contacts.length > 0 && (
                            <Descriptions.Item label={`Guests (${contacts.length})`} span={3}>
                                {contacts.map((contact) => `${contact.lastname}/${contact.firstname}`).join(', ')}
                            </Descriptions.Item>
                        )}
                    </Descriptions>
                </>
            );
        } else {
            return <Empty description="No booking information available" />;
        }
    };

    const pricingInfo = (
        <Descriptions bordered column={4}>
            <Descriptions.Item label="Market Value">${orderItemData.marketValue ?? 'N/A'}</Descriptions.Item>
            <Descriptions.Item label="Service Fee">${orderItemData.serviceFee ?? 'N/A'}</Descriptions.Item>
            <Descriptions.Item label="Total">${orderItemData.total ?? 'N/A'}</Descriptions.Item>
            <Descriptions.Item label="Commission">${orderItemData.commission ?? 'N/A'}</Descriptions.Item>
        </Descriptions>
    );

    const information = (information) => {
        const renderInfoList = (role) => (
            <List
                dataSource={(information || []).filter((item) => item.role === role)}
                renderItem={(item) => <List.Item>{item.content}</List.Item>}
                locale={{ emptyText: 'No information available' }}
            />
        );

        const tabItems = Object.values(ActivityInfoRole).map((role) => ({
            key: String(role),
            label: ActivityInfoRoleParse(role),
            children: renderInfoList(role),
        }));

        return (
            <Tabs
                defaultActiveKey={String(ActivityInfoRole.Include)}
                items={tabItems}
                tabPosition={screens.xs ? 'top' : 'left'}
                style={{ marginTop: 20 }}
            />
        );
    };

    return (
        <div className="order-item-detail">
            <Title level={4}>Order Item Details</Title>
            {renderInCard('Order Information', orderInfo)}
            {renderInCard('Booking Information', bookingInfo())}
            {renderInCard('Activity Information', activityInfo())}
            {renderInCard('Pricing Information', pricingInfo)}
        </div>
    );
}

OrderItemDetail.propTypes = {
    orderItemData: PropTypes.object,
    contacts: PropTypes.array,
};

export default OrderItemDetail;
