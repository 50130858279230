import React from 'react';
import PropTypes from 'prop-types';
import { Icon, Popup, PopupContent, Table } from 'semantic-ui-react';
import OrderItemsTableRow from './OrderItemsTableRow';

function OrderItemsTable({ orderItems = [], total = 0, commissionAmount = 0, callback = () => {} }) {
    return (
        <Table celled>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>订单号</Table.HeaderCell>
                    <Table.HeaderCell>名称</Table.HeaderCell>
                    <Table.HeaderCell>日期</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">
                        单价{' '}
                        <Popup trigger={<Icon name="info circle" />}>
                            <PopupContent>包含GST</PopupContent>
                        </Popup>
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">数量</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">总价</Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">佣金</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">操作</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {orderItems.map((item, index) => {
                    return <OrderItemsTableRow key={index} item={item} callback={callback} />;
                })}
            </Table.Body>
            <Table.Footer>
                <Table.Row>
                    <Table.HeaderCell colSpan="5"></Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">
                        <h4>A${total}</h4>
                    </Table.HeaderCell>
                    <Table.HeaderCell textAlign="right">
                        {!!commissionAmount && `A$${commissionAmount}`}
                    </Table.HeaderCell>
                    <Table.HeaderCell></Table.HeaderCell>
                </Table.Row>
            </Table.Footer>
        </Table>
    );
}

OrderItemsTable.propTypes = {
    orderItems: PropTypes.array.isRequired,
    total: PropTypes.number.isRequired,
    serviceFee: PropTypes.number,
    callback: PropTypes.func.isRequired,
};

export default OrderItemsTable;
